import * as React from 'react';
import { Col, Row } from 'antd';

import StatsCard from '../StatsCard';
import { StatsCardType } from '../StatsCard/StatsCard';

export interface IProperties {
  clients: number;
  components: number;
  deploy: number;
  projects: number;
  users: number;
  websites: number;
}

const Statistics = (stats: IProperties) => {
  return (
    <div
      style={{ paddingTop: 42, paddingBottom: 24, minWidth: 800, maxWidth: 800, margin: 'auto' }}
    >
      <Row gutter={24}>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Users}
            value={stats.users}
          />
        </Col>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Project}
            value={stats.projects}
          />
        </Col>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Websites}
            value={stats.websites}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 24 }} gutter={24}>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Clients}
            value={stats.clients}
          />
        </Col>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Deploy}
            value={stats.deploy}
          />
        </Col>
        <Col span={8}>
          <StatsCard
            type={StatsCardType.Components}
            value={stats.components}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
