import * as React from "react";
import { Alert, Divider } from "antd";
import { useSelector } from "react-redux";

import { deploySelectors } from "@source/redux/deploy";
import Upload from "../Upload";
import ComponentsTable from "../ComponentsTable";

export interface IProperties {
  id: string;
}

const Form = ({ id }: IProperties) => {
  const server = useSelector(deploySelectors.selectServer(id));
  const activeComponents = useSelector(deploySelectors.selectActiveComponents(id));

  if (!server) {
    return (
      <Alert message={"Invalid server!"} description={"We are unable to load server information. Please make sure, that URL is correct..."} type={"error"} />
    );
  }

  return (
    <>
      <h2>{server.name}</h2>
      {activeComponents ? (
        <>
          <span>
            <b>Current:</b> {activeComponents.name}
          </span>
          <br />
          <span>
            <b>Notes:</b> {activeComponents.notes}
          </span>
        </>
      ) : (
        <span>There are currently no active components!</span>
      )}
      <Divider />
      <Upload serverId={id} />
      <Divider />
      <ComponentsTable serverId={id} />
    </>
  );
};

export default Form;
