import * as React from "react";
import { Button, Input, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import history from "@source/services/history";
import Actions from "./components/Actions";
import { deployActions, deploySelectors } from "@source/redux/deploy";

const cutId = (id: string): string => {
  const res = `#${id.substr(0, 6)}...`;
  return res;
};

const columns = [
  {
    align: "center" as "center",
    dataIndex: "id",
    render: (rec: string) => <b>{cutId(rec)}</b>,
    title: "ID",
    width: "130px",
    key: "id"
  },
  {
    dataIndex: "name",
    title: "Name",
    key: "name"
  },
  {
    align: "center" as "center",
    dataIndex: "user",
    title: "User",
    key: "user"
  },
  {
    align: "center" as "center",
    dataIndex: "host",
    title: "Host",
    key: "host"
  },
  {
    align: "center" as "center",
    dataIndex: "id",
    render: (id: string) => <Actions id={id} />,
    title: "Actions",
    key: "actions"
  }
];

const Deploy = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const servers = useSelector(deploySelectors.selectServerList);

  const updateSearch = (val: string) => {
    setSearch(val);
  };

  useEffect(() => {
    dispatch(deployActions.getServerList());
  }, []);

  const handleNewServer = () => {
    history.push("/server");
  };

  const loading = false;

  // Filter data before render
  const filterRegEx = RegExp(`.*${search}.*`);
  let filteredData = [...servers];
  if (search.length > 1) {
    filteredData = servers.filter(rec => {
      return filterRegEx.test(rec.name) || filterRegEx.test(rec.host);
    });
  }

  return (
    <div>
      <Row style={{ marginBottom: "42px" }}>
        <Button type="primary" style={{ marginRight: "24px" }} onClick={handleNewServer}>
          Add new Server
        </Button>
        <Input.Search placeholder="Search for Server" enterButton="Search" style={{ width: "520px" }} onSearch={updateSearch} />
      </Row>
      <Row>
        <Table columns={columns} dataSource={filteredData} size="middle" loading={loading} rowKey={server => `server-${server.id}`} />
      </Row>
    </div>
  );
};

export default Deploy;
