import { Action } from '../store';
import * as types from './types';
import { Components, Server } from '@source/services/rest';

export interface State {
  servers: Server[],
  components: {
    [serverId: string]: Components[];
  },
  fetching: string | undefined; // ID of currently fetching components (for example if activating)
}

const initialState: State = {
  servers: [],
  components: {},
  fetching: undefined,
};

export const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case types.SAVE_SERVER_LIST:
      return {
        ...state,
        servers: [...action.payload],
      };
    case types.SAVE_COMPONENTS_LIST:
      return {
        ...state,
        components: {
          ...state.components,
          [action.payload.serverId]: [...action.payload.list],
        },
        fetching: undefined,
      };
    case types.SET_FETCHING:
      return {
        ...state,
        fetching: action.payload.id as string | undefined,
      };
    default:
      return state;
  }
}
