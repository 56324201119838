import * as React from "react";
import { useState } from "react";
import { Button, Icon, Input, notification, Row, Spin, Switch, Upload as UploadAntd } from "antd";
import { UploadComponentsDto } from "@source/services/rest";
import { useDispatch, useSelector } from "react-redux";

import { deployActions, deploySelectors } from "@source/redux/deploy";
import { UploadFile } from "antd/lib/upload/interface";

const rowStyle = {
  marginBottom: "16px"
} as React.CSSProperties;

type UploadState = Partial<UploadComponentsDto>;

export interface IProperties {
  serverId: string;
}

const Upload = ({ serverId }: IProperties) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [data, setData] = useState<UploadState>({
    file: undefined,
    notes: "",
    active: true
  });
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const fetching = useSelector(deploySelectors.selectFetchingComponents);

  const handleUpload = () => {
    if (!data.file) {
      setErr(true);
      return openErrorNotification();
    }

    dispatch(deployActions.uploadComponents(serverId, data as UploadComponentsDto));
    setFileList([]);
    setData({
      file: undefined,
      notes: "",
      active: true
    });
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    setData(old => ({
      ...old,
      notes: e.target.value
    }));
  };

  const handleActiveChange = (active: boolean) => {
    setData(old => ({
      ...old,
      active
    }));
  };

  const openErrorNotification = () => {
    const args = {
      message: "No file selected",
      description: "You have to choose some file to upload component set."
    };
    notification.error(args);
  };

  const uploadAntdProps = {
    onRemove: () => {
      setData(old => ({ ...old, file: undefined }));
      setFileList([]);
    },
    beforeUpload: (file: UploadFile) => {
      setFileList([file]);
      if (file && err) setErr(false);
      setData(old => ({ ...old, file: (file as unknown) as File }));
      return false;
    },
    fileList
  };

  return (
    <Spin spinning={fetching === "new"} delay={200} tip={"Uploading..."}>
      <h3>Upload component set</h3>
      <Row style={rowStyle}>
        <UploadAntd {...uploadAntdProps}>
          <Button>
            <Icon type="file-zip" /> Select component file
          </Button>
        </UploadAntd>
      </Row>
      <Row style={rowStyle}>
        <label>
          <b>Notes:</b>
        </label>
        <Input.TextArea value={data.notes} onChange={handleNotesChange} />
      </Row>
      <Row style={rowStyle}>
        <label>
          <b>Activate:</b>
        </label>
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          defaultChecked
          checked={data.active ? true : false}
          onChange={handleActiveChange}
          style={{ marginLeft: "8px" }}
        />
      </Row>
      <Row style={{ textAlign: "right" }}>
        <Button type={"primary"} onClick={handleUpload}>
          <Icon type={"upload"} />
          Upload
        </Button>
      </Row>
      {/* </Card> */}
    </Spin>
  );
};

export default Upload;
