import * as React from 'react';

import Form from './components/Form';
import {useSelector, useDispatch} from "react-redux";
import {deployActions, deploySelectors} from "@source/redux/deploy";
import { useEffect } from 'react';

export interface IProperties {
  location: {
    pathname: string;
  };
}

const Components = (props: IProperties) => {
  const serverIdRegex = /^.*components\/([a-zA-Z0-9]+)$/i;
  const match = serverIdRegex.exec(props.location.pathname);

  let id = '';
  if (match && match[1] && match[1].length > 2) {
    id = match[1];
  } else {
    return (
      <>
        <span>Components Scene</span>
        <br />
        <span>Invalid URL! No server ID was specified...</span>
      </>
    );
  }

  const dispatch = useDispatch();
  const server = useSelector(deploySelectors.selectServer(id));

  useEffect(() => {
    if (!server) {
      // Try to fetch servers
      dispatch(deployActions.getServerList());
    }
    dispatch(deployActions.getComponentsList(id));
  }, [server]);

  return (
    <>
      <Form
        id={id}
      />
    </>
  );
};

export default Components;
