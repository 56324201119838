import * as React from 'react';
import { useState, useEffect } from 'react';

import Statistics from './components/Statistics';
import { AuthStats, DeployStats, restApi } from '@source/services/rest';

const useAuthStats = () => {
  const [stats, setStats] = useState<AuthStats>({
    clients: 0,
    projects: 0,
    users: 0,
    websites: 0,
  });

  useEffect(() => {
    restApi.authStats()
      .then((data) => {
        if (!data) return;
        setStats(data);
      })
  }, []);

  return stats;
}

const useDeployStats = () => {
  const [stats, setStats] = useState<DeployStats>({
    components: 0,
    servers: 0,
  });

  useEffect(() => {
    restApi.deployStats()
      .then((data) => {
        if (!data) return;
        setStats(data);
      });
  }, []);

  return stats;
};

const Dashboard = () => {
  const authStats = useAuthStats();
  const deployStats = useDeployStats();

  return (
    <>
      <h2>Welcome in Foxer360 Auth Dashboard</h2>
      <span>Here you can take a look at some statistics over Foxer360 Ecosystem</span>
      <Statistics
        clients={authStats.clients}
        components={deployStats.components}
        deploy={deployStats.servers}
        projects={authStats.projects}
        users={authStats.users}
        websites={authStats.websites}
      />
    </>
  );
};

export default Dashboard;
