import * as React from "react";
import { useState } from "react";
import { Button, Icon, Input, Popconfirm, Spin, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deployActions, deploySelectors } from "@source/redux/deploy";
import { ColumnProps } from "antd/lib/table";
import { Components } from "@source/services/rest";

export interface IProperties {
  serverId: string;
}

interface NotesProperties {
  id: string;
  text: string;
}

const Notes = ({ id, text }: NotesProperties) => {
  const [value, setValue] = useState(text);
  const [isEditing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const handleEdit = () => {
    if (isEditing) {
      setValue(text);
    }
    setEditing(!isEditing);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    setEditing(false);
    dispatch(deployActions.updateComponents(id, value));
  };

  if (!isEditing) {
    return (
      <div>
        <span>{text}</span>
        <Button size="small" onClick={handleEdit} style={{ marginLeft: "8px" }} icon="edit" />
      </div>
    );
  }

  return (
    <div>
      <Input.TextArea value={value} onChange={handleChange} autosize />
      <div style={{ float: "right", marginTop: "8px" }}>
        <Button size="small" onClick={handleEdit} icon="close" />
        <Button size="small" onClick={handleSave} style={{ marginLeft: "4px" }} type="primary" icon="save" />
      </div>
    </div>
  );
};

const ComponentsTable = ({ serverId }: IProperties) => {
  const dispatch = useDispatch();
  const components = useSelector(deploySelectors.selectComponentsList(serverId));
  const fetchingId = useSelector(deploySelectors.selectFetchingComponents);

  if (!components || components.length === 0) {
    return <span>There are no components...</span>;
  }

  const activateComponents = (id: string) => () => {
    dispatch(deployActions.activateComponents(id));
  };

  const deleteComponents = (id: string) => () => {
    dispatch(deployActions.deleteComponents(id));
  };

  const columns: ColumnProps<Components>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: "Upload date",
      key: "created",
      render: (_, component) => {
        return <span>{component.created.toLocaleString()}</span>;
      },
      sorter: (a, b) => Date.parse(a.created.toString()) - Date.parse(b.created.toString()),
      defaultSortOrder: "descend"
    },
    {
      title: "Notes",
      key: "notes",
      render: (_, component) => <Notes id={component.id} text={component.notes} />,
      sorter: (a, b) => a.notes.localeCompare(b.notes)
    },
    {
      title: "Tags",
      key: "tags",
      render: (_, component) => {
        if (component.active) return <Tag color="green">Active</Tag>;
        return <Tag color="volcano">Inactive</Tag>;
      },
      sorter: (a, b) => {
        return Number(a.active) - Number(b.active);
      }
    },
    {
      title: "Action",
      key: "action",
      render: (_, component) => (
        <>
          <Popconfirm title="Are you sure?" okText="Delete" cancelText="Keep" okType="danger" onConfirm={deleteComponents(component.id)}>
            <Button type="danger" style={{ marginRight: "6px" }}>
              <Icon type={"delete"} />
            </Button>
          </Popconfirm>
          <Button onClick={activateComponents(component.id)}>
            <Icon type={"upload"} />
          </Button>
        </>
      ),
      width: 130
    }
  ];

  return (
    <Spin spinning={!!fetchingId && fetchingId.length > 8} delay={200} tip="Activating...">
      <Table columns={columns} dataSource={components} title={() => <h3>Uploaded components</h3>} rowKey={component => component.id} size="small" />
    </Spin>
  );
};

export default ComponentsTable;
