import { defaults } from './defaults';
import { resolvers } from './resolvers';
import { typeDefs } from './typedefs';


export {
  defaults,
  resolvers,
  typeDefs,
};
