import { applyMiddleware, Store as ReduxStore, createStore } from 'redux';
import { reducer, State } from './reducer';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { saga } from './saga';
import {isDevelopment} from "@source/utils";

export interface Action {
  type: string;
  payload?: any;
}

export type Store = ReduxStore<State, Action>;

let store: Store | undefined;

/**
 * Get instance of the redux store
 *
 * @throws {string} error if store cannot be initialized
 * @return {Store} initialized redux store
 */
export const getReduxStore = (): Store  => {
  if (store !== undefined) return store;

  // Initialize store
  const sagaMiddleware = createSagaMiddleware();
  if (isDevelopment()) {
    const logger = createLogger({
      collapsed: true,
      level: 'info',
    });
    store = createStore(reducer, applyMiddleware(sagaMiddleware, logger));
  } else {
    store = createStore(reducer, applyMiddleware(sagaMiddleware));
  }

  // Run root saga
  if (sagaMiddleware) {
    sagaMiddleware.run(saga);
  }

  // Check for correct initialization
  if (store === undefined || store === null) {
    throw new Error('Failed to initialize Redux Store!');
  }

  return store;
};
