import { Breadcrumb, Icon } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import gql from "graphql-tag";
import {useQuery} from "react-apollo-hooks";
import {useSelector} from "react-redux";
import {deploySelectors} from "@source/redux/deploy";

export interface IProperties {
  path?: string;
}

interface PathSegment {
  icon: string,
  name: string,
  path: string,
}

const DashboardSegment: PathSegment = {
  icon: 'home',
  name: 'Auth Dashboard',
  path: '/',
};

const ClientsSegment: PathSegment = {
  icon: 'deployment-unit',
  name: 'Clients',
  path: '/clients',
};

const NewClientSegment: PathSegment = {
  icon: 'deployment-unit',
  name: 'Add new Client',
  path: '/client',
};

const UsersSegment: PathSegment = {
  icon: 'team',
  name: 'Users',
  path: '/users',
};

const NewUserSegment: PathSegment = {
  icon: 'user',
  name: 'Add new User',
  path: '/user',
};

const DeploySegment: PathSegment = {
  icon: 'cloud-server',
  name: 'Deploy',
  path: '/deploy',
};

const NewDeploySegment: PathSegment = {
  icon: 'cloud-server',
  name: 'Add new Deploy server',
  path: '/server',
};

const SettingsSegment: PathSegment = {
  icon: 'setting',
  name: 'Settings',
  path: '/settings',
};

const NotFoundSegment: PathSegment = {
  icon: 'eye',
  name: 'Not found',
  path: '/notfound',
}

const USER_NAME = gql`
  query ($id: ID!) {
    user (
      where: { id: $id }
    ) {
      name
    }
  }
`;

const CLIENT_NAME = gql`
  query ($id: ID!) {
    client (
      where: { id: $id }
    ) {
      name
    }
  }
`;

const pathToSegments = (path?: string): PathSegment[] => {
  if (!path) return [DashboardSegment];
  const segments = path.split('/').filter((s) => s.trim().length > 0);

  if (segments.length === 0 || segments[0].toLowerCase() === 'dashboard') return [DashboardSegment];
  const result = [DashboardSegment];

  // Get data for clients and users
  const { data: userData } = useQuery(USER_NAME, {
    variables: { id: segments[1] },
    fetchPolicy: 'cache-only'
  });
  const { data: clientData } = useQuery(CLIENT_NAME, {
    variables: { id: segments[1] },
    fetchPolicy: 'cache-only',
  });

  const server = useSelector(deploySelectors.selectServer(segments[1]));

  switch (segments[0]) {
    case 'client':
      result.push(ClientsSegment);
      if (clientData && clientData.client) {
        result.push({
          icon: 'deployment-unit',
          name: clientData.client.name,
          path: `/client/${segments[1]}`,
        });
      } else if (segments[1] === undefined) {
        result.push(NewClientSegment);
      }
      break;
    case 'clients':
      result.push(ClientsSegment);
      break;
    case 'user':
      result.push(UsersSegment);
      if (userData && userData.user) {
        result.push({
          icon: 'user',
          name: userData.user.name,
          path: `/user/${segments[1]}`,
        });
      } else if (segments[1] === undefined) {
        result.push(NewUserSegment);
      }
      break;
    case 'users':
      result.push(UsersSegment);
      break;
    case 'components':
      result.push(DeploySegment);
      result.push({
        icon: 'eye',
        name: 'Components Overview',
        path: `/components/${segments[1]}`,
      });
      break;
    case 'deploy':
      result.push(DeploySegment);
      break;
    case 'server':
      result.push(DeploySegment);
      if (segments[1] !== undefined) {
        result.push({
          icon: 'cloud-server',
          name: `${server ? server.name : 'Edit Server'}`,
          path: `/server/${segments[1]}`,
        });
      } else {
        result.push(NewDeploySegment);
      }
      break;
    case 'settings':
      result.push(SettingsSegment);
      break
    default:
      result.push(NotFoundSegment);
  }

  return result;
};


const Navigation = ({ path }: IProperties) => {
  const segments = pathToSegments(path);

  return (
    <Breadcrumb>
      { segments.map((segment, index) => (
        <Breadcrumb.Item key={`segment-${index}`}>
          <Link className="ant-breadcrumb-link" to={segment.path}>
            <Icon type={segment.icon} />
            <span>{segment.name}</span>
          </Link>
        </Breadcrumb.Item>
      )) }
    </Breadcrumb>
  );
};

export default Navigation;
