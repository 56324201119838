// Actions with server manipulation
export const GET_SERVER_LIST = '@deploy/get-server-list';
export const SAVE_SERVER_LIST = '@deploy/save-server-list';
export const DELETE_SERVER = '@deploy/delete-server';
export const CREATE_SERVER = '@deploy/create-server';
export const UPDATE_SERVER = '@deploy/update-server';

// Actions with components manipulation
export const GET_COMPONENTS_LIST = '@deploy/get-components-list';
export const SAVE_COMPONENTS_LIST = '@deploy/save-components-list';
export const DELETE_COMPONENTS = '@deploy/delete-components';
export const ACTIVATE_COMPONENTS = '@deploy/activate-components';
export const SET_FETCHING = '@deploy/set-fetching';
export const UPDATE_COMPONENTS = '@deploy/update-components';
export const UPLOAD_COMPONENTS = '@deploy/upload-components';
