import { Button, Popconfirm } from 'antd';
import * as React from 'react';

import history from '@source/services/history';
import {useDispatch} from "react-redux";
import { deployActions } from '@source/redux/deploy';

const Actions = ({ id }: { id: string }) => {
  const dispatch = useDispatch();

  const handleUpload = () => {
    // Move to uploading scene (
    history.push(`/components/${id}`);
  };

  const handleEdit = () => {
    history.push(`/server/${id}`);
  };

  const handleDelete = () => {
    // Call API to delete server
    dispatch(deployActions.deleteServer(id));
  };

  return (
    <>
      <Button
        type="default"
        icon="upload"
        size="small"
        style={{ marginRight: '8px' }}
        onClick={handleUpload}
      >
        Upload
      </Button>
      <Button
        type="primary"
        icon="edit"
        size="small"
        style={{ marginRight: '8px' }}
        onClick={handleEdit}
      >
        Edit
      </Button>
      <Popconfirm
        title="Are you sure?"
        okText="Delete"
        cancelText="Keep"
        okType="danger"
        onConfirm={handleDelete}
      >
        <Button
          type="danger"
          icon="delete"
          size="small"
        >
          Delete
        </Button>
      </Popconfirm>
    </>
  );
};

export default Actions;
