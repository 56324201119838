import { State } from '../index';
import { Components } from '@source/services/rest';

export const selectServerList = (state: State) => {
  return state.deploy.servers;
};

export const selectServer = (id: string) => (state: State) => {
  return state.deploy.servers.find(server => server.id === id);
};

export const selectComponentsList = (serverId: string) => (state: State) => {
  return state.deploy.components[serverId];
};

export const selectActiveComponents = (serverId: string) => (state: State) => {
  const components = state.deploy.components[serverId];
  if (!components || components.length === 0) return undefined;

  return components.find(component => component.active);
};

export const selectAllComponents = (state: State): Components[] => {
  let components: Components[] = [];
  for (const key in state.deploy.components) {
    components = [...components, ...state.deploy.components[key]];
  }

  return components;
};

export const selectServerIdForComponents = (componentsId: string) => (state: State) => {
  const components = selectAllComponents(state);
  const found = components.find((c) => c.id === componentsId);
  if (!found) return undefined;

  return found.server;
};

export const selectFetchingComponents = (state: State) => {
  return state.deploy.fetching;
}
