import { Components, Server, UploadComponentsDto } from '@source/services/rest';
import { Action } from '../store';
import { CreateServerDto } from '@source/services/rest';

import * as types from './types';

export const getServerList  = (): Action => ({
  type: types.GET_SERVER_LIST,
});

export const saveServerList = (list: Server[]): Action => ({
  type: types.SAVE_SERVER_LIST,
  payload: list,
});

export const deleteServer = (id: string): Action => ({
  type: types.DELETE_SERVER,
  payload: { id },
});

export const createServer = (dto: CreateServerDto): Action => ({
  type: types.CREATE_SERVER,
  payload: dto,
});

export const updateServer = (id: string, dto: CreateServerDto): Action => ({
  type: types.UPDATE_SERVER,
  payload: { id, dto },
});

export const getComponentsList = (serverId: string): Action => ({
  type: types.GET_COMPONENTS_LIST,
  payload: { serverId },
});

export const saveComponentsList = (serverId: string, list: Components[]): Action => ({
  type: types.SAVE_COMPONENTS_LIST,
  payload: { serverId, list },
});

export const deleteComponents = (id: string): Action => ({
  type: types.DELETE_COMPONENTS,
  payload: { id },
});

export const activateComponents = (id: string): Action => ({
  type: types.ACTIVATE_COMPONENTS,
  payload: { id },
})

export const setFetching = (id: string | undefined): Action => ({
  type: types.SET_FETCHING,
  payload: { id },
});

export const updateComponents = (id: string, notes: string): Action => ({
  type: types.UPDATE_COMPONENTS,
  payload: { id, notes },
})

export const uploadComponents = (serverId: string, data: UploadComponentsDto):Action => ({
  type: types.UPLOAD_COMPONENTS,
  payload: { serverId, data },
});
