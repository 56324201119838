import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as types from "./types";
import * as selectors from "./selectors";
import { Components, restApi, Server } from "@source/services/rest";
import { Action } from "../store";
import { notification } from "antd";

function* getServerList() {
  let servers: Server[] = yield select(selectors.selectServerList);
  if (servers.length > 0) return;

  servers = yield call(restApi.getServerList);
  if (!servers || servers.length === 0) return;

  yield put(actions.saveServerList(servers));
}

function* deleteServer(action: Action) {
  const id = action.payload.id;
  if (!id) return;

  let result: boolean = yield call(restApi.deleteServer, id);
  if (!result) return;

  let servers: Server[] = yield select(selectors.selectServerList);
  servers = servers.filter(server => server.id !== id);

  yield put(actions.saveServerList(servers));
}

function* createServer(action: Action) {
  const server: Server = yield call(restApi.createServer, action.payload);
  if (!server) return;

  let servers: Server[] = yield select(selectors.selectServerList);
  servers = [...servers, server];

  yield put(actions.saveServerList(servers));
}

function* updateServer(action: Action) {
  const { id, dto } = action.payload;
  if (!id) return;

  let result: Server = yield call(restApi.updateServer, id, dto);
  if (!result) return;

  let servers: Server[] = yield select(selectors.selectServerList);
  if (!servers) return;

  servers = servers.map(server => {
    if (server.id !== id) return server;
    return result;
  });

  yield put(actions.saveServerList(servers));
}

function* getComponentsList(action: Action) {
  const { serverId } = action.payload;
  if (!serverId) return;

  let components: Components[] = yield select(selectors.selectComponentsList(serverId));
  if (components && components.length > 0) return;

  components = yield call(restApi.getComponentsList, serverId);
  if (!components || components.length === 0) return;

  yield put(actions.saveComponentsList(serverId, components));
}

function* activateComponents(action: Action) {
  const { id } = action.payload;
  if (!id) {
    notification.error({
      message: "Activating error",
      description: `No component id provided.`
    });
    return;
  }

  yield put(actions.setFetching(id));
  const result: Components | undefined = yield call(restApi.activateComponents, id);
  if (!result) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Activating error",
      description: `Activating component set failed.`
    });
    return;
  }

  const serverId: string = yield select(selectors.selectServerIdForComponents(id));
  if (!serverId) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Activating error",
      description: `No server id.`
    });
    return;
  }
  const components: Components[] = yield select(selectors.selectComponentsList(serverId));
  if (!components) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Activating error",
      description: `No components returned.`
    });
    return;
  }
  const res = components.map((component: Components) => {
    if (component.id !== id)
      return {
        ...component,
        active: false
      };

    return result;
  });

  yield put(actions.saveComponentsList(serverId, res));

  notification.success({
    message: "Activated successfully",
    description: `Name: ${result.name}\nNotes: ${result.notes}`
  });
}

function* deleteComponents(action: Action) {
  const { id } = action.payload;
  if (!id) {
    notification.error({
      message: "Delete error",
      description: `No component id provided.`
    });
    return;
  }

  const serverId: string = yield select(selectors.selectServerIdForComponents(id));
  if (!serverId) {
    notification.error({
      message: "Delete error",
      description: `No server id.`
    });
    return;
  }

  const success: boolean = yield call(restApi.deleteComponents, id);
  if (!success) {
    notification.error({
      message: "Delete error",
      description: `Deleting component set failed.`
    });
    return;
  }

  const components: Components[] = yield select(selectors.selectComponentsList(serverId));
  if (!components) {
    notification.error({
      message: "Delete error",
      description: `No components returned.`
    });
    return;
  }

  const res = components.filter((component: Components) => component.id !== id);

  yield put(actions.saveComponentsList(serverId, res));

  const component = components.find(c => c.id === id);

  component &&
    notification.success({
      message: "Deleted successfully",
      description: `Name: ${component.name}\nNotes: ${component.notes}`
    });
}

function* updateComponents(action: Action) {
  const { id, notes } = action.payload;
  if (!id) {
    notification.error({
      message: "Update error",
      description: `No component id provided.`
    });
    return;
  }

  yield put(actions.setFetching(id));
  const result: Components | undefined = yield call(restApi.updateComponents, id, { notes });
  if (!result) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Update error",
      description: `Updating components set failed.`
    });
    return;
  }

  const serverId: string = yield select(selectors.selectServerIdForComponents(id));
  if (!serverId) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Update error",
      description: `No server id.`
    });
    return;
  }
  const components: Components[] = yield select(selectors.selectComponentsList(serverId));
  if (!components) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Update error",
      description: `No components returned.`
    });
    return;
  }
  const res = components.map((component: Components) => {
    if (component.id !== id) return { ...component };
    return result;
  });

  yield put(actions.saveComponentsList(serverId, res));

  notification.success({
    message: "Updated successfully",
    description: `Name: ${result.name}\nNotes: ${result.notes}`
  });
}

function* uploadComponents(action: Action) {
  const { serverId, data } = action.payload;

  yield put(actions.setFetching("new"));
  const result: Components | undefined = yield call(restApi.uploadComponents, serverId, data);
  if (!result) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Upload error",
      description: `Upload components failed.`
    });
    return;
  }

  let components: Components[] = yield select(selectors.selectComponentsList(serverId));
  if (!components) {
    yield put(actions.setFetching(undefined));
    notification.error({
      message: "Upload error",
      description: `No components returned.`
    });
    return;
  }
  components = components.map(c => {
    if (data.active) return { ...c, active: false };
    return c;
  });

  const updatedComponents = [...components, result];

  yield put(actions.saveComponentsList(serverId, updatedComponents));

  notification.success({
    message: "Uploaded successfully",
    description: `Name: ${result.name}\nNotes: ${result.notes}`
  });
}

export function* saga() {
  yield all([
    takeLatest(types.ACTIVATE_COMPONENTS, activateComponents),
    takeLatest(types.CREATE_SERVER, createServer),
    takeLatest(types.DELETE_COMPONENTS, deleteComponents),
    takeLatest(types.DELETE_SERVER, deleteServer),
    takeLatest(types.GET_COMPONENTS_LIST, getComponentsList),
    takeLatest(types.GET_SERVER_LIST, getServerList),
    takeLatest(types.UPDATE_COMPONENTS, updateComponents),
    takeLatest(types.UPDATE_SERVER, updateServer),
    takeLatest(types.UPLOAD_COMPONENTS, uploadComponents)
  ]);
}
