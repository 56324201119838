import { readEnvironmentVariable } from '@source/utils';
import { isLoggedIn, getAccessToken } from '@source/services/auth0';


interface ObjectId {
  '$oid': string;
}

interface ObjectDate {
  '$date': {
    '$numberLong': string;
  }
}

export interface AuthStats {
  clients: number;
  projects: number;
  users: number;
  websites: number;
}

export interface DeployStats {
  components: number;
  servers: number;
}

export interface Server {
  id: string,
  name: string,
  user: string,
  host: string,
  folder: string,
  created: Date,
  updated: Date,
}

export interface CreateServerDto {
  name: string,
  user: string,
  host: string,
  folder: string,
}

export interface Components {
  id: string,
  active: boolean,
  name: string,
  notes: string,
  server: string,
  created: Date,
  updated: Date,
}

export interface UploadComponentsDto {
  file: File;
  notes: string;
  active: boolean;
}

export interface UpdateComponentDto {
  notes?: string;
  active?: boolean;
}

const mapObjectIdToId = (id: ObjectId): string => id.$oid;

const mapObjectDateToDate = (date: ObjectDate): Date => {
  return new Date(parseInt(date.$date.$numberLong));
};

const mapApiObject = (obj: any): any => {
  const id = mapObjectIdToId(obj._id);
  const created = mapObjectDateToDate(obj.created);
  const updated = mapObjectDateToDate(obj.updated);

  const res = {...obj, id, created, updated};
  delete res._id;

  return res;
};

const SimpleRESTApi = (authUrl: string, deployUrl: string) => {

  const getHeaders = (): Headers => {
    const accessToken = getAccessToken();
    if (accessToken === null) throw new Error('Access Token is not set');

    const headers = new Headers();
    headers.set(`Authorization`, `Bearer ${accessToken}`);

    return headers;
  };

  const authStats = async (): Promise<AuthStats | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${authUrl}/stats`, {
      method: 'GET',
      headers,
    });

    try {
      const res = await fetch(req);
      const data = await res.json();

      if (!data || data.clients === undefined || data.clients === null) return undefined;

      return data as AuthStats;
    } catch {
      return undefined;
    }
  };

  const deployStats = async (): Promise<DeployStats | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/stats`, {
      method: 'GET',
      headers,
    });

    try {
      const res = await fetch(req);
      const data = await res.json();

      if (!data || data.components === undefined || data.components === null) return undefined;

      return data as DeployStats;
    } catch {
      return undefined;
    }
  };

  const getServerList = async (): Promise<Server | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/servers`, {
      method: 'GET',
      headers,
    });

    try {
      const res = await fetch(req);
      const data = await res.json();

      if (!data) return undefined;

      return data.map((raw: any) => mapApiObject(raw) as Server);
    } catch {
      return undefined;
    }
  };

  const getComponentsList = async (serverId: string): Promise<Components | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/components/${serverId}`, {
      method: 'GET',
      headers,
    });

    try {
      const res = await fetch(req);
      const data = await res.json();

      if (!data) return undefined;

      return data.map((raw: any) => {
        const res = mapApiObject(raw);
        res.server = mapObjectIdToId(res.server);

        return res as Components;
      });
    } catch {
      return undefined;
    }
  };

  const createServer = async (data: CreateServerDto): Promise<Server | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/server`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    try {
      const res = await fetch(req);
      const server = await res.json();

      if (res.status !== 200 && res.status !== 201) return undefined;
      if (!server) return undefined;

      return mapApiObject(server) as Server;
    } catch {
      return undefined;
    }
  };

  const updateServer = async (id: string, data: CreateServerDto): Promise<Server | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/server/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });

    try {
      const res = await fetch(req);
      const server = await res.json();

      if (res.status !== 200 && res.status !== 201) return undefined;
      if (!server) return undefined;

      return mapApiObject(server) as Server;
    } catch {
      return undefined;
    }
  };

  const deleteServer = async (id: string): Promise<boolean> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/server/${id}`, {
      method: 'DELETE',
      headers,
    });

    try {
      const res = await fetch(req);
      return res.status === 200;
    } catch {
      return false;
    }
  };

  const deleteComponents = async (id: string): Promise<boolean> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/components/${id}`, {
      method: 'DELETE',
      headers,
    });

    try {
      const res = await fetch(req);
      return res.status === 200;
    } catch {
      return false;
    }
  };

  const activateComponents = async (id: string): Promise<Components | undefined> => {
    return updateComponents(id, { active: true });
  };

  const updateComponents = async (id: string, data: UpdateComponentDto): Promise<Components | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const headers = getHeaders();
    const req = new Request(`${deployUrl}/components/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });

    try {
      const res = await fetch(req);
      const components = await res.json();

      if (res.status !== 200 && res.status !== 201) return undefined;
      if (!components) return undefined;

      return mapApiObject(components) as Components;
    } catch {
      return undefined;
    }
  }

  const uploadComponents = async (serverId: string, data: UploadComponentsDto): Promise<Components | undefined> => {
    if (!isLoggedIn()) throw new Error('You are not logged in');

    const body = new FormData();
    body.append('file', data.file);
    body.append('notes', data.notes);
    body.append('active', data.active ? 'true' : 'false');

    const headers = getHeaders();
    // headers.set('content-type', 'multipart/form-data');
    const req = new Request(`${deployUrl}/components/${serverId}`, {
      method: 'POST',
      headers,
      body,
    });

    try {
      const res = await fetch(req);
      const components = await res.json();

      if (res.status !== 200 && res.status !== 201) return undefined;
      if (!components) return undefined;

      return mapApiObject(components) as Components;
    } catch {
      return undefined;
    }
  }

  return {
    activateComponents,
    authStats,
    createServer,
    deleteComponents,
    deleteServer,
    deployStats,
    getComponentsList,
    getServerList,
    updateComponents,
    updateServer,
    uploadComponents,
  };
}

const authServer = readEnvironmentVariable("AUTH_SERVER_REST_URL");
const deployServer = readEnvironmentVariable("DEPLOY_SERVER");

export const restApi = SimpleRESTApi(authServer, deployServer);
