import * as React from 'react';
import { Card, Icon, Statistic } from 'antd';

export enum StatsCardType {
  Components = 'components',
  Clients = 'clients',
  Deploy = 'deploy',
  Project = 'projects',
  Users = 'users',
  Websites = 'websites',
}

export interface IProperties {
  type: StatsCardType,
  value: number;
}

const typeToName = (type: StatsCardType): string => {
  switch (type) {
    case StatsCardType.Components:
      return 'Uploaded components';
    case StatsCardType.Clients:
      return 'Clients';
    case StatsCardType.Deploy:
      return 'Deployed Servers';
    case StatsCardType.Project:
      return 'Projects';
    case StatsCardType.Users:
      return 'Users';
    case StatsCardType.Websites:
      return 'Websites';
    default:
      return 'Unknown';
  }
};

const typeToIcon = (type: StatsCardType): string => {
  switch (type) {
    case StatsCardType.Components:
      return 'file-zip';
    case StatsCardType.Clients:
      return 'deployment-unit';
    case StatsCardType.Deploy:
      return 'cloud-server';
    case StatsCardType.Project:
      return 'project';
    case StatsCardType.Users:
      return 'team';
    case StatsCardType.Websites:
      return 'global';
    default:
      return 'unknown';
  }
};

const  StatsCard = ({ type, value }: IProperties) => {
  return (
    <Card
      title={typeToName(type)}
      size={'small'}
      style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)' }}
    >
      <Statistic
        value={value}
        prefix={<Icon type={typeToIcon(type)} />}
        style={{ textAlign: 'center' }}
      />
    </Card>
  );
};

export default StatsCard;
