import { Button, Input, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';


export interface IProperties {
  isNew: boolean;
  values?: {
    id?: string;
    name: string;
    user: string;
    host: string;
    folder: string;
  };

  onCancel?: () => void;
  onSave?: (data: any, create: boolean) => void;
}

export interface IState {
  touched: boolean;
  name: string;
  user: string;
  host: string;
  folder: string;
}

const defaultValues = {
  name: '',
  user: '',
  host: '',
  folder: 'foxer360',
  touched: false,
} as IState;

const Form = (props: IProperties) => {
  const [state, setState] = useState<IState>(defaultValues);

  useEffect(() => {
    // Copy props into state
    if (!state.touched && !props.isNew) {
      if (!props.values) { return; }

      setState({
        ...props.values,
        touched: true,
      });
    }
  });

  const handleValueChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setState(old => ({
      ...old,
      [name]: e.target.value,
      touched: true,
    }));
  };

  const handleAction = (name: string) => () => {
    if (name === 'save' && props.onSave) {
      const data = {
        name: state.name,
        user: state.user,
        host: state.host,
        folder: state.folder,
      };
      props.onSave(data, props.isNew);
      return;
    }

    if (name === 'cancel' && props.onCancel) {
      props.onCancel();
      return;
    }
  };

  const rowStyle = {
    marginBottom: '16px',
  } as React.CSSProperties;

  const containerStyle = {
    margin: '12px auto',
    maxWidth: '720px',
  } as React.CSSProperties;

  return (
    <>
      <div style={containerStyle}>
        {!props.isNew ?
          <Row style={rowStyle}>
            <label>Server ID</label>
            <Input disabled={true} value={props.values ? props.values.id : ''} />
          </Row>
          :
          null
        }
        <Row style={rowStyle}>
          <label>Name</label>
          <Input onChange={handleValueChange('name')} value={state.name} />
        </Row>
        <Row style={rowStyle}>
          <label>User</label>
          <Input onChange={handleValueChange('user')} value={state.user} />
        </Row>
        <Row style={rowStyle}>
          <label>Host</label>
          <Input onChange={handleValueChange('host')} value={state.host} />
        </Row>
        <Row style={rowStyle}>
          <label>Folder</label>
          <Input onChange={handleValueChange('folder')} value={state.folder} />
        </Row>
      </div>
      <Row>
        <div style={{ width: '100% !important', float: 'right', marginRight: '60px' }}>
          <Button type="primary" onClick={handleAction('save')}>{props.isNew ? 'Create' : 'Save'}</Button>
          <Button style={{ marginLeft: '12px' }} onClick={handleAction('cancel')}>Cancel</Button>
        </div>
      </Row>
    </>
  );
};

export default Form;
