import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { deployActions, deploySelectors } from '@source/redux/deploy';
import history from "@source/services/history";
import Form from "./components/Form";
import {Alert, Button} from "antd";

export interface IProperties {
  location: {
    pathname: string;
  };
}

const ServerDetail = (props: IProperties) => {
  const serverIdRegex = /^.*server\/([a-zA-Z0-9]+)$/i;
  const match = serverIdRegex.exec(props.location.pathname);
  const dispatch = useDispatch();

  let isNew = true;
  let id = '';
  if (match && match[1] && match[1].length > 2) {
    isNew = false;
    id = match[1];
  }

  const server = useSelector(deploySelectors.selectServer(id));

  const handleSave = (formData: any, create: boolean) => {
    if (create) {
      // Dispatch create
      dispatch(deployActions.createServer(formData));
    } else {
      // Dispatch update
      dispatch(deployActions.updateServer(id, formData));
    }
    history.push('/deploy');
  };

  const handleCancel = () => {
    history.push('/deploy');
  };

  if (isNew) {
    return (
      <div>
        <Form
          isNew={true}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </div>
    );
  }

  if (!server) {
    return (
      <div>
        <Alert
          message="Error"
          description={'Server which you trying to edit doesnt exists!'}
          type="error"
          showIcon={true}
        />
        <div style={{ textAlign: 'center' }}>
          <Button style={{ marginTop: '24px' }} type="primary" onClick={handleCancel}>Go Back</Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Form
        isNew={false}
        values={{
          id,
          name: server.name,
          user: server.user,
          host: server.host,
          folder: server.folder,
        }}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </div>
  );
};

export default ServerDetail;
